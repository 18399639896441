import Resume from "../components/Resume/Resume";
import PageBuilder from "../common/PageBuilder/PageBuilder";
import { forwardRef } from "react";
import { normalizeResumeData } from "../utils/resume.helper";
import { resumeMockData } from "../data/resume.data";

interface ResumePageProps {
  id: string;
  isActive: boolean;
}

const ResumePage = forwardRef<HTMLDivElement, ResumePageProps>(
  ({ id, isActive }, ref) => {
    const resumeData = normalizeResumeData(resumeMockData);

    const resumeComponent = resumeData && (
      <Resume
        workExperiences={resumeData.workExperiences}
        skills={resumeData.technicalSkills}
        educations={resumeData.educations}
        certifications={resumeData.certifications}
      />
    );

    return (
      <div id={id} ref={ref}>
        <PageBuilder
          id="resume-page-content"
          title="Resume"
          subtitle1="I Develop Skills Regularly To Keep Me Updated"
          children={resumeComponent}
          isActive={isActive}
        />
      </div>
    );
  }
);

export default ResumePage;
