import { createContext, useContext, useEffect, useState } from "react";
import ProfileType from "../types/Profile.type";
import { normalizeProfileData } from "../utils/profile.helper";
import { profileMockData } from "../data/profile.data";

interface ProfileContextType {
    profileData: ProfileType | null;
    loading: boolean;
}

const ProfileContext = createContext<ProfileContextType>({
    profileData: null,
    loading: true,
});

export const useProfile = () => useContext(ProfileContext);

export const ProfileProvider: React.FC<any> = ({ children }) => {
    const [profileData, setProfileData] = useState<ProfileType | null>(null);

    useEffect(() => {
        const normalizeProfileObj = normalizeProfileData(profileMockData)
        setProfileData(normalizeProfileObj);
    }, [profileData]);

    return (
        <ProfileContext.Provider value={{ profileData, loading: false }}>
            {children}
        </ProfileContext.Provider>
    );
}