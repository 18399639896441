import Portfolio from "../components/Portfolio/Portfolio";
import PageBuilder from "../common/PageBuilder/PageBuilder";
import { forwardRef, useEffect, useState } from "react";
import {
  getDistinctProjectTabs,
  normalizeProjectData,
} from "../utils/project.helper";
import { projectMockData } from "../data/project.data";

interface PortfolioPageProps {
  id: string;
  isActive: boolean;
}

const PortfolioPage = forwardRef<HTMLDivElement, PortfolioPageProps>(
  ({ id, isActive }, ref) => {
    const projectData = normalizeProjectData(projectMockData);
    const [tabs, setTabs] = useState<string[]>(["all"]);

    useEffect(() => {
      const distinctTabs = getDistinctProjectTabs(projectData);
      setTabs(distinctTabs);
    }, [projectData]);

    const portfolioComponent = projectData?.length ? (
      <Portfolio tabs={tabs} projects={projectData} />
    ) : null;

    return (
      <div id={id} ref={ref}>
        <PageBuilder
          id="portfolio-page-content"
          title="Portfolio"
          subtitle1="My Amazing Works"
          children={portfolioComponent}
          isActive={isActive}
        />
      </div>
    );
  }
);

export default PortfolioPage;
