export const resumeMockData: any = {
    _id: "660107330b5c60ba2faa82cf",
    profileId: "65fc5712ed5f13b56354a7a2",
    workExperiences: [
        {
            organization: "Tata Consultancy Services",
            jobs: [
                {
                    title: "System Engineer",
                    startDate: "Oct 2022",
                    endDate: "Present",
                    jobtype: "Full-time",
                    description: "As part of the Ally Auto Finance project, I contribute to the cloud modernization of an internal customer management application by migrating it from Ember to React.js. I work with a team to implement a modern tech stack including React, TypeScript, JavaScript, RTK Query, PrimeReact, Metronome-UI, HTML, and CSS, within a micro-frontend architecture using AWS, GitLab, Docker and Webpack. Collaborating actively with multiple developer squads."
                },
                {
                    title: "System Engineer Trainee",
                    startDate: "Oct 2021",
                    endDate: "Sep 2022",
                    jobtype: "Full-time",
                    description: "Received comprehensive training in modern web development with React and TypeScript, gaining hands-on experience in Java, Spring Boot, and SQL for REST API development and backend integration. Developed a Timesheet app as a hands-on project to showcase acquired skills."
                }
            ]
        },
        {
            organization: "Chegg Inc.",
            jobs: [
                {
                    title: "Subject Matter Expert",
                    startDate: "Jun 2020",
                    endDate: "Aug 2022",
                    jobtype: "Freelance",
                    description: "Solved diverse coding problems using various languages such as Java, Python, JavaScript, HTML, and CSS."
                }
            ]
        }
    ],
    technicalSkills: [
        {
            name: "React",
            rating: 8,
            _id: "660d9637fd070fd31d7c58f8"
        },
        {
            name: "TypeScript",
            rating: 7,
            _id: "660d9637fd070fd31d7c58f9"
        },
        {
            name: "JavaScript",
            rating: 8,
            _id: "660d9637fd070fd31d7c58fa"
        },
        {
            name: "Node.js",
            rating: 6,
            _id: "660d9637fd070fd31d7c58fb"
        },
        {
            name: "Nest.js & Express.js",
            rating: 6,
            _id: "660d9637fd070fd31d7c58fc"
        },
        {
            name: "Redux",
            rating: 6,
            _id: "660d9637fd070fd31d7c58fd"
        },
        {
            name: "Git & GitLab",
            rating: 8,
            _id: "660d9637fd070fd31d7c58fe"
        },
        {
            name: "Html",
            rating: 9,
            _id: "660d9637fd070fd31d7c58ff"
        },
        {
            name: "CSS",
            rating: 8,
            _id: "660d9637fd070fd31d7c5900"
        },
        {
            name: "SQL",
            rating: 5,
            _id: "660d9637fd070fd31d7c5901"
        },
        {
            name: "MongoDB",
            rating: 5,
            _id: "660d9637fd070fd31d7c5902"
        },
        {
            name: "Python",
            rating: 7,
            _id: "660d9637fd070fd31d7c5903"
        },
        {
            name: "Java",
            rating: 6,
            _id: "660d9637fd070fd31d7c5904"
        }
    ],
    educations: [
        {
            institution: "Aliah University, Kolkata",
            degree: "Bachelor of Technology",
            stream: "Computer Science & Engineering",
            startYear: "Jul 2016",
            endYear: "Jun 2020"
        }
    ],
    certifications: [],
    createdAt: "2024-03-25T05:10:11.605Z",
    updatedAt: "2024-04-03T17:47:35.319Z",
    __v: 0
}